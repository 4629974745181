import React, { Component } from 'react'

export default class Section extends Component {


    constructor(props) {
        super(props);
        this.divStyle = {
            minHeight: '100vh',
            backgroundColor: this.props.bgcolor,
            padding: '40px',
            color: this.props.color
        };
        if (this.props.bgimage) {
            this.divStyle.backgroundImage = `url(${this.props.bgimage})`;
            this.divStyle.backgroundPosition = 'center';
            this.divStyle.backgroundRepeat = 'no-repeat';
            this.divStyle.backgroundSize = 'cover';
            this.divStyle.backgroundColor = '#00000055';
        }

        if (this.props.align) {
            this.divStyle.display = 'flex';
            this.divStyle.alignItems = 'center';
            this.divStyle.justifyContent = 'center';
        }
    }


    render() {
        return (
            <div id={this.props.id} style={this.divStyle}>
                <div>
                    {this.props.children}
                </div>

            </div>
        )
    }
}