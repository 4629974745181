import React from 'react'
import styles from './Header.module.css';


export default function Header() {
    return (
        <div className={styles.container}>
            <h1 style={{marginRight:'10px'}}>Antikstadl</h1>
            <p>Entrümpelungen
            Räumungen
            Entsorgungen
            Umzüge
            Transporte
            Ankauf von Verlassenschaften
            An- und Verkauf von Antiquitäten
</p>

        </div>
    )
}
