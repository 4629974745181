import React, { Component } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';


export default class AppBar extends Component {
    constructor(props) {
        super(props);

        this.style = {
            trans: 'navbar navbar-transparent',
            black: 'navbar navbar-color '
        };

        this.state = {
            activeStyle: this.style.trans,
            scrollSize: 100,
            isMobile: window.innerWidth <= 800,
            menuClass: 'collapse navbar-collapse'
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let el = document.scrollingElement || document.documentElement;
        let scrollTop = el.scrollTop;
        //let scrollTop = event.srcElement.scrollingElement.scrollTop;

        if (scrollTop > this.state.scrollSize) {
            this.setState({
                activeStyle: this.style.black
            });
        } else {
            this.setState({
                activeStyle: this.style.trans
            });
        }
    };

    onNavClick = (event) => {
        event.preventDefault();
        let href = event.currentTarget.getAttribute('href');
        let el = document.getElementById(href);

        //el.scrollIntoView({ block: "start", behavior: "smooth" });
        el.scrollIntoView();
        if (this.state.isMobile) {
            this.setState({
                menuClass: 'collapse navbar-collapse open-drawer'
            });
        }
    };

    closeMenu = () => {
        this.setState({
            menuClass: 'collapse navbar-collapse open-drawer',
            isMobile: true
        });
    };

    toggleMenu = () => {
        this.setState({
            menuClass: 'open-drawer-active open-drawer',
            isMobile: true
        });
    };


    render() {
        return (
            <div className="header">
                <nav className={this.state.activeStyle}>
                    <div>
                        <div className="navbar-header">
                            <div className="menu-icon" onClick={this.toggleMenu}>
                                <div className="menu-open-icon">
                                    <FaBars />
                                </div>

                                <div className="mobile-menu-heading" style={{marginTop:'20px'}}>
                                    <img src="./images/logo.svg" width="120px" alt="Logo des Antikstadls" />

                                </div>
                            </div>
                            <div className={this.state.menuClass} id="custom-collapse">
                                <div className="closeicon" onClick={this.closeMenu}>
                                    <FaTimes />
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <a href="top" onClick={this.onNavClick} className="section-scroll">
                                                Home
											</a>
                                        </li>
                                        <li>
                                            <a href="services" onClick={this.onNavClick} className="section-scroll">
                                                Dienstleistungen
											</a>
                                        </li>
                                        <li>
                                            <a href="realestate" onClick={this.onNavClick} className="section-scroll">
                                                Verlassenschaften
											</a>
                                        </li>

                                        <li>
                                            <a href="contact" onClick={this.onNavClick} className="section-scroll">
                                                Kontakt
											</a>
                                        </li>
                                    </ul>
                                </div>
                                <div style={{ textAlign: 'right', marginTop: '20px', marginRight: '15px' }}>

                                    <img src="./images/logo.svg" width="120px" alt="Logo des Antikstadls" />



                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}
