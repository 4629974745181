import React from 'react';
import ReactDOM from 'react-dom';
import { Route,  BrowserRouter as Router } from 'react-router-dom';
import Impressum from './components/Impressum';
import UnderConstruction from './components/UnderConstruction';
import App from './App';

const routing = (
  <Router>
    <div>
      <Route exact  path="/construction" component={UnderConstruction} />
      <Route exact  path="/" component={App} />
      <Route path="/impressum" component={Impressum} />
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))