import React, { Component } from 'react';
import '../styles/App.css';
import '../styles/Menu.css';
import Section from './Section';
import AppBar from './AppBar';
import Header from './Header';



export default class Antikstadl extends Component {

	render() {

		return (
			<div>
				<AppBar />
				<Section id="top" bgimage="./images/furniture_bg.jpg">
					<Header></Header>
				</Section>
				<Section id="services" color="#DDDDDD" bgcolor="#000000bf">
					<h3 style={{ textAlign: 'center' }}>Wohnungsräumungen</h3>
					<p>Sie brauchen professionelle Hilfe bei der Räumung einer Wohnung? Unter der Telefonnummer +43 650 68 29 437 hilft Ihnen unser Räumungsteam rasch und zuverlässig weiter</p>
					<p>Die Räumung von Kellern, Dachböden oder der gesamten Wohnung muss keineswegs stressig und anstrengend sein. Schon gar nicht mit einem professionellen Räumungsunternehmen wie uns an Ihrer Seite. Egal, ob es sich um eine Hausräumung, die Räumung einer Gemeindewohnung, eine Zwangsräumung oder eine Verlassenschaft handelt, wir sind stets ein zuverlässiger Partner, wenn es um Räumungen jeglicher Art geht!</p>
					<h5>Wertausgleich</h5>
					<p>Wir bieten unseren Kunden einen Wertausgleich auf jeden Artikel, der verkauft werden kann, sprich, je mehr verwertbare Artikel, desto günstiger wird die Räumung </p>
					<h5>Stressfreie Räumung mit unserem ALL-INKLUSIVE PAKET</h5>
					<ul>
						<li>Kostenloses Beratungsgespräch mit unverbindlichem Kostenvoranschlag</li>
						<li>Fixpreisgarantie</li>
						<li>keine versteckten Kosten</li>
						<li>Kostenminderung durch Gegenrechnung von Verwertbaren Gegenständen </li>
						<li>felxible und kurzfristige Termine (auch am Wochenende)		</li>
						<li>schnelle und professionelle Räumung</li>
						<li>besenreine Übergabe</li>

					</ul>
					<h3>Entsorgungen</h3>
					<p>Sie sind am ausmisten haben einiges zum Entsorgen?
					Bücher, Möbel, Deko usw.
					Gerne machen wir Ihnen ein unverbindliches Angebot.
					Termine auch abends oder am Wochenende möglich!</p>

					<h3>Altwaren/Antiquitäten</h3>
					<p>
						Wir handeln mit Antiquitäten (An- und Verkauf)
						Über einen Besuch in unserem Laden in Dornbirn freuen wir uns sehr.
						Falls Sie etwas bestimmtes suchen können Sie gerne bei uns anfragen.
						Natürlich auch wenn Sie etwas schönes zu verkaufen haben

					</p>

				</Section>
				<Section id="realestate" color="#DDDDDD" bgcolor="#575351" align="center">
					<h3 style={{ textAlign: 'center' }}>Verlassenschaften</h3>
					<ul style={{ listStyleType: 'none', margin: '0', padding: '0', textAlign: 'center' }}>
						<li>Kostenlose Erstbesichtigung</li>
						<li>Unverbindlicher Kostenvoranschlag</li>
						<li>Transparente Preisgestaltung mit Fixpreis-Garantie</li>
						<li>Keine zusätzlichen nachträglichen</li>
						<li>Kostenminderung durch Wertausgleich</li>
						<li>Flexible und kurzfristige Termine (auch am Wochenende)</li>
						<li>Effiziente Planung und Durchführung</li>
						<li>Schnelle und diskrete Arbeitsweise</li>
						<li>Umweltschonende Entsorgung</li>
						<li>Besenreine Übergabe</li>

					</ul>
				</Section>
				<Section id="contact" color="black" align="center" bgcolor="#efefef">
					<div style={{ textAlign: 'center' }}>
						<h3>Kontakt</h3>
						<p>Max Karl Herburger</p>
						<p>Tel: 0043 664 308 42 47</p>
						<p>info@antik-stadl.at</p>
						<a href="/impressum">Impressum</a>
					</div>
				</Section>

			</div>
		);
	}


}

